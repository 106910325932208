import { ref, unref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { remove } from 'lodash-es'
import qs from 'qs'

export const useApiFactory = (entityName) => {
    const route = useRoute()

    const entityNameUnref = unref(entityName)

    const searchingEndpoint = (queryParams) => {
        queryParams = qs.stringify(unref(queryParams))
        return `/api/${entityNameUnref}?${queryParams}`
    }
    const gettingEndpoint = (idParam) => `/api/${entityNameUnref}/${idParam}`
    const creatingEndpoint = () => `/api/${entityNameUnref}`
    const savingEndpoint = (idParam) => `/api/${entityNameUnref}/${idParam}`
    const deletingEndpoint = (idParam) => `/api/${entityNameUnref}/${idParam}`

    const entity = ref(null)
    const entities = ref({
        data: [],
        total: 0,
        last_page: 0,
        current_page: 0,
    })

    const setEntity = (newEntity) => {
        entity.value = newEntity
    }
    const setEntities = (newEntities) => {
        entity.value = newEntities
    }

    const setDataRecord = (updateData, identity = 'id') => {
        const updateDataIdentify = updateData?.[identity] || null
        if (!updateDataIdentify) {
            return
        }

        const itemIndex = entities.value.data.findIndex(
            (item) => `${item[identity]}` === `${updateDataIdentify}`
        )
        if (itemIndex < 0) {
            return
        }

        for (const [key, value] of Object.entries(updateData)) {
            if (key === identity) {
                continue
            }

            entities.value.data[itemIndex][key] = value
        }
    }

    /**********************************
     *      Searching composable
     **********************************/
    const useApiSearch = (
        queries = {},
        shouldWatch = true,
        skipFirstRun = false
    ) => {
        const { replaceEndpoint, execute, ...state } = useApi(
            searchingEndpoint(queries),
            'GET'
        )

        const setQueryFilters = (queryParams) => {
            replaceEndpoint(searchingEndpoint(queryParams))
        }

        const executeSearch = (queryParams = null, options = {}) => {
            if (queryParams) {
                setQueryFilters(queryParams)
            }

            return execute(options).then((result) => {
                entities.value = result
                return result
            })
        }

        const executeRefreshSearch = (options = {}) =>
            executeSearch(
                {
                    ...route.params,
                    ...route?.query,
                    ...queries,
                },
                options
            )

        if (shouldWatch) {
            watch(
                () => route?.query,
                (newQuery) =>
                    !skipFirstRun
                        ? executeSearch({ ...queries, ...newQuery })
                        : null,
                { immediate: true, flush: 'post' }
            )
        }

        return {
            execute: executeSearch,
            executeRefreshSearch,
            setQueryFilters,
            ...state,
        }
    }

    /**********************************
     *      Getting composable
     **********************************/
    const useApiGet = (id = '') => {
        const { replaceEndpoint, execute, ...state } = useApi(
            gettingEndpoint(id),
            'GET'
        )

        const setId = (idParam) => {
            replaceEndpoint(gettingEndpoint(idParam))
        }

        const executeGet = (idParam = null) => {
            if (idParam) {
                setId(idParam)
            }

            return execute().then((result) => {
                entity.value = result
                return result
            })
        }

        return {
            execute: executeGet,
            setId,
            ...state,
        }
    }

    /**********************************
     *      Creating composable
     **********************************/
    const useApiCreate = () => {
        const { execute, ...state } = useApi(creatingEndpoint(), 'POST')

        const executeCreate = (data, options = {}) => {
            return execute({ data, ...options }).then((result) => {
                entity.value = result
                return result
            })
        }

        return {
            execute: executeCreate,
            ...state,
        }
    }

    /**********************************
     *      Saving composable
     **********************************/
    const useApiSave = (id = '', method = 'PUT') => {
        const { replaceEndpoint, execute, ...state } = useApi(
            savingEndpoint(id),
            method
        )

        const setId = (idParam) => {
            replaceEndpoint(savingEndpoint(idParam))
        }

        const executeSave = (data, idParam = null, options = {}) => {
            if (idParam) {
                setId(idParam)
            } else if (entity.value?.id) {
                setId(entity.value.id)
            }

            return execute({ data, ...options }).then((result) => {
                entity.value = result
                return result
            })
        }

        return {
            execute: executeSave,
            setId,
            ...state,
        }
    }

    /**********************************
     *      Deleting composable
     **********************************/
    const useApiDelete = (id = '') => {
        const { replaceEndpoint, execute, ...state } = useApi(
            deletingEndpoint(id),
            'DELETE'
        )

        const setId = (idParam) => {
            replaceEndpoint(deletingEndpoint(idParam))
        }

        const executeDelete = (idParam, options = {}) => {
            if (idParam) {
                setId(idParam)
            } else if (entity.value?.id) {
                setId(entity.value.id)
            }

            return execute(options).then(() => {
                entities.value.data = remove(
                    entities.value.data,
                    (currentEntity) => {
                        return currentEntity.id === id
                    }
                )
                entity.value = null
                return null
            })
        }

        return {
            execute: executeDelete,
            setId,
            ...state,
        }
    }

    return {
        entity,
        entities,
        setDataRecord,
        setEntity,
        setEntities,
        useApiSearch,
        useApiGet,
        useApiCreate,
        useApiSave,
        useApiDelete,
    }
}
